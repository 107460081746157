import clsx from 'clsx';
import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { AsChild, InternalComponentProps } from '../Slot';
import { stack, StackVariants } from './Stack.css';

export type StackProps = {
  children: React.ReactNode;
  id?: string;
} & StackVariants;

type StackComponentProps = StackProps & AsChild & InternalComponentProps;

export const Stack = React.forwardRef<HTMLDivElement, StackComponentProps>(
  ({ children, className = '', asChild, as = 'div', style = {}, id, ...variants }, ref) => {
    const classNames = stack({ ...variants });
    const Component = asChild ? Slot : as;

    return (
      <Component
        className={clsx(classNames, className)}
        style={{
          ...style,
        }}
        id={id}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);

