import React, { AnchorHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import ReactIs from 'react-is';
import { AsChild, InternalComponentProps } from '../Slot';
import { Stack } from '../Stack';
import { Box } from '../Box';
import { ToneFocus } from '../ToneFocusProvider';
import * as styles from './TextLink.css';

type AnchorProps = Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target' | 'download'>;

type TextLinkProps = AsChild &
  InternalComponentProps &
  AnchorProps & {
    children: React.ReactNode;
    beforeIcon?: React.ReactNode;
    afterIcon?: React.ReactNode;
    onClick?: () => void;
  } & styles.TextLinkVariants &
  styles.IconVariants;

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  (
    {
      children,
      target,
      href,
      beforeIcon,
      afterIcon,
      className = '',
      style = {},
      tone = 'onLight',
      asChild,
      variant,
      onClick,
    },
    ref
  ) => {
    const linkProps = {
      ...(ref && { ref }),
      ...(href && { href }),
      ...(target && { target }),
      rel: target === '_blank' ? 'noopener noreferrer' : undefined,
      onClick: onClick,
    };

    const recipeClassNames = styles.root({ tone, variant });
    const iconClassNames = styles.icon({ tone });
    if (!children || typeof children === 'boolean') return null;

    const text =
      asChild && ReactIs.isElement(children)
        ? (children.props.children as React.ReactNode)
        : children;

    const buttonContent = (
      <>
        {beforeIcon ? (
          <Box asChild className={iconClassNames}>
            {beforeIcon}
          </Box>
        ) : null}
        <span className={styles.text}>{text}</span>
        {afterIcon ? (
          <Box asChild className={iconClassNames}>
            {afterIcon}
          </Box>
        ) : null}
      </>
    );

    return (
      <Stack
        direction="row"
        gap={2}
        asChild
        className={clsx(className, recipeClassNames)}
        style={style}
      >
        {/* We always replace the children, so we can add our own markup and icons */}
        {ReactIs.isElement(children) && asChild ? (
          <ToneFocus tone={tone}>
            {React.cloneElement(children, {
              ...children.props,
              children: buttonContent,
              ...linkProps,
              className: clsx(children.props.className, recipeClassNames, className),
            })}
          </ToneFocus>
        ) : (
          <ToneFocus tone={tone}>
            <a {...linkProps} className={clsx(recipeClassNames, className)}>
              {buttonContent}
            </a>
          </ToneFocus>
        )}
      </Stack>
    );
  }
);

